import React, { useEffect, useState } from "react";
import "./App.css"
import axios from "axios"
import parse from 'html-react-parser';
import { useParams } from "react-router-dom";

export default function App() {

  let params = useParams();
  const [input_field, setInputField] = useState({ meeting_name: '', })
  const [form2_data, setForm2Data] = useState({ name: '', session_id: '', host: false })
  const [control, setControll] = useState(false)
  const [html_data, setHtmlData] = useState('')


  useEffect(() => {
    if (control) {
      let data = {
        "meeting_name": input_field.meeting_name,
      }
      axios.post('https://apivideointrv.skillablers.com/api/createSession', data,{headers:{'org-token': params.token}})
        .then(res => {
          setForm2Data({ name: '', session_id: res.data.data.session_id, host: false })
          setInputField({ meeting_name: '',})
          setControll(false)
        })
        .catch(function (error) { 
          setControll(false)
          alert('api error')
          console.log('errrrrrrrrrrrrrrrrr::::===',error);
        })
    }
  }, [control])

  const HandleChange = (e) => {
    const { name, value } = e.target
    setInputField({ ...input_field, [name]: value })
  }

  const SubmitForm = (e) => {
    e.preventDefault()
    setControll(true)
  }

  const HandleRadio = (e) => {
    setForm2Data({ ...form2_data, [e.target.name]: e.target.checked })
  }

  const SubmitForm2 = (e) => {
    e.preventDefault()

    axios.post('https://apivideointrv.skillablers.com/api/connect', form2_data,{headers:{'org-token': params.token}})
        .then(res => {
          setHtmlData(res.data)
        })
        .catch(function (error) {
          alert('api error')
          console.log('errrrrrrrrrrrrrrrrr::::===',error);
        })
  }
  
  const HandleName=(e)=>{
    setForm2Data({...form2_data,[e.target.name]:e.target.value})
  }
  return (
    <>
      {
        html_data ?
          <div className="wrap_output">{parse(html_data)}</div>
          :
          <div className="App">
             <form onSubmit={SubmitForm}>
              <h3>create session</h3>
              <input name="meeting_name" placeholder="Meeting name" value={input_field.meeting_name} onChange={HandleChange} />

              <button>
                {control ? "Submitting..." : "Submit"}

              </button>
            </form>

            <form onSubmit={SubmitForm2}>
              <h3>submit session</h3>
              <input name="name" placeholder="Name" value={form2_data.name} onChange={HandleName} />
              <input name="session_id" placeholder="Session id" onChange={HandleName} value={form2_data.session_id} />
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <label>Host : </label>
                <input style={{ height: 'auto', marginTop: '10px', width: 'auto' }} type="checkbox" name="host" checked={form2_data.host} onChange={HandleRadio} />
              </div>

              <button>
                Submit
              </button>

            </form>
          </div>
      }

    </>
  );
}
