import React from 'react'
import { BrowserRouter,Routes,Route } from "react-router-dom";
import App from "./App"

function Main() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<div>No page found</div>} />
                <Route path="/:token" element={<App />} />
                <Route path="*" element={<div>No page found</div>} />

            </Routes>
        </BrowserRouter>
    )
}

export default Main
